let navigationHistory: any = []
let searchValue = null
export class navigationService {
  handleRefresh() {
    let sessionStorageItem = this.getSessionStorage()

    for (let i = 0; i < sessionStorageItem.length; i++) {
      navigationHistory.push(sessionStorageItem[i])
    }
  }

  setNavigationPath(s: {}) {
    let sessionStorageItem = this.getSessionStorage()
    if (navigationHistory.length === 0 && sessionStorageItem !== null) {
      this.handleRefresh()
    }
    if (s['path'] === '/home') {
      this.clearArray()
    }
    navigationHistory.push(s)
    if (navigationHistory[0].path !== '/home') {
      navigationHistory.splice(0, navigationHistory.length - 1)
    }
    this.setSessionStorage()
  }
  updateState(y: string) {
    navigationHistory[navigationHistory.length - 2].state = y
    this.setSessionStorage()
    searchValue = null
  }
  backPerviousPage() {
    navigationHistory.splice(navigationHistory.length - 2, 2)
    this.setSessionStorage()
  }
  backHome() {
    navigationHistory.splice(0, navigationHistory.length)
    this.setSessionStorage()
  }
  clickSideBar(id) {
    console.log(id, 'navigation')
    let foundNS = false
    let foundHome = false
    let sessionStorageItem = this.getSessionStorage()
    if (navigationHistory.length === 0 && sessionStorageItem !== null) {
      this.handleRefresh()
    }
    for (let i = 0; i < navigationHistory.length; i++) {
      foundNS = navigationHistory[i].path === '/notification-setup'
      foundHome = navigationHistory[i].path === '/home'
      if (id === 'Notitication') {
        if (foundNS) navigationHistory.splice(1, 2)
      }
      if (id === 'Home') {
        if (foundHome) navigationHistory.splice(0)
      }
    }
    this.setSessionStorage()
  }
  setSearchValue(s: string) {
    searchValue = s
  }
  getSearchValue() {
    return searchValue
  }
  clearArray() {
    navigationHistory.splice(0, navigationHistory.length)
    navigationHistory.push({ path: '/dashboard', state: '' })
    this.setSessionStorage()
  }
  //when projectid change
  removeProject(page: number = 2) {
    //navigationHistory[navigationHistory.length-1].path
    // navigationHistory.splice(navigationHistory.length - page, 1)
    navigationHistory.splice(1, 1)
    this.setSessionStorage()
  }
  setSessionStorage() {
    sessionStorage.setItem(
      'navigationHistory',
      JSON.stringify(navigationHistory)
    )
  }
  getSessionStorage() {
    return JSON.parse(sessionStorage.getItem('navigationHistory'))
  }
}
