import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'

const AccessSecurityMenuList = lazy(() => import('./AccessSecurityMenuListing'))
const UserList = lazy(() => import('./User/UserListing'))
const UserDetail = lazy(() => import('./User/UserDetail'))
const RoleList = lazy(() => import('./Roles/RoleListing'))

export const ACCESS_SECURITY_PATH = {
  ACCESS_SECURITY_MENU_LIST: '/access-security',
  USERLIST: '/access-security/user',
  NEWUSER: '/access-security/user/new',
  EDITUSER: '/access-security/user/edit',
  VIEWUSER: '/access-security/user/view',
  ROLELIST: '/access-security/role',
  NEWROLE: '/access-security/role/new',
  EDITROLE: '/access-security/role/edit',
  VIEWROLE: '/access-security/role/view',
}

const AccessSecurityRoutes:RouteObject[] = [
  {
    path: "",
    element: <AccessSecurityMenuList /> 
  },
  {
    path: "User",
    children:[
      {
        path: "",
        element: <UserList />
      },
      {
        path: `view/:userId`,
        element: <UserDetail />
      }
    ]
  },
  {
    path: "Role",
    children:[
      {
        path: "",
        element: <RoleList />
      }
    ]
  }
]

export default AccessSecurityRoutes
