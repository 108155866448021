import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
const NotificationListing = lazy(() => import('./NotificationListing'))

const NotificationRoutes: RouteObject[] = [
  {
    path: 'notification-list/:id',
    element: (
      <NotificationListing
        style={{ marginTop: '0px' }}
        // projectIdProject = {projectIdProject}
        // totalProject={0}
        // setNotificationCount={}
        // notificationCount={0}
      />
    ),
  },
]
export default NotificationRoutes
