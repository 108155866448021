import AcctRoutes from 'containers/modules/Account/Routes'
import NotificationSetupRoutes from '../modules/NotificationSetup/Routes'
import { Navigate, useRoutes } from 'react-router-dom'
import MainLayout from '../app/MainLayout'
import AccessSecurityRoutes from '../modules/AccessSecurity/Routes'
import AuthRoutes, { AUTH_PATH } from '../modules/Authentication/Routes'
import HomeRoutes from '../modules/Home/Routes'
import { AuthGuard } from './AuthGuard'
import { Blank } from './Blank'

export function MainRoutes() {
  const RootRoutes = useRoutes([
    {
      path: '',
      element: <AuthGuard />,
      children: [
        {
          path: '',
          element: <MainLayout />,
          children: [
            {
              path: '',
              element: <Navigate to="/home" />,
            },
            {
              path: 'profile',
              children: [...AcctRoutes],
            },
            {
              path: 'home',
              children: [...HomeRoutes],
            },
            {
              path: 'access-security',
              children: [...AccessSecurityRoutes],
            },
            {
              path: 'notification-setup',
              children: [...NotificationSetupRoutes],
            },
          ],
        },
      ],
    },
    {
      path: '',
      element: <Blank />,
      children: [...AuthRoutes],
    },
    {
      path: '*',
      element: <Navigate replace to={AUTH_PATH.NOT_FOUND}></Navigate>,
    },
  ])

  return RootRoutes
}
// export default RootRoutes
