import { createUploadLink } from 'apollo-upload-client'
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import {
  ClearAccessToken,
  getAccessToken,
  getRegistrationToken,
  setAccessToken,
} from 'AccessToken'
import jwtDecode from 'jwt-decode'
import { setLoading } from 'containers/helper/loadingHelper'
import { navigateTo } from './containers/helper/navigationSubjectHelper'
import { setSnackBar } from './containers/helper/snackBarSubjectHelper'
import { AUTH_PATH } from 'containers/modules/Authentication/Routes'
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  Observable,
  HttpLink,
  RequestHandler,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'

let apiNum: number = 0

let nodeUrl = import.meta.env.VITE_API_URL

export let GoogleAPIKey = import.meta.env.VITE_GoogleAPIKey

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle: any
      Promise.resolve(operation)
        .then(operation => {
          const accessToken = getAccessToken()
          setLoading(true)
          apiNum++
          if (accessToken) {
            operation.setContext({
              headers: {
                authorization: `Bearer ${accessToken}`,
                registrationToken: getRegistrationToken(),
              },
            })
          }
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) handle.unsubscribe()
      }
    })
)

const responseData = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    if (apiNum > 0) {
      --apiNum
    }
    let resultResponse = JSON.parse(JSON.stringify(response))
    if (apiNum === 0) {
      setLoading(false)
    }
    return resultResponse
  })
})

const uploadLink = createUploadLink({
  uri: nodeUrl,
  credentials: 'include',
})

export const client = new ApolloClient({
  link: ApolloLink.from([
    new TokenRefreshLink({
      accessTokenField: 'refreshToken',
      isTokenValidOrUndefined: () => {
        const token = getAccessToken()
        if (!token) {
          return true
        }

        try {
          const { exp } = jwtDecode(token)
          if (Date.now() >= exp * 1000) {
            return false
          } else {
            return true
          }
        } catch (err) {
          console.log(err)
          return false
        }
      },
      fetchAccessToken: () => {
        return fetch(`${nodeUrl}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: JSON.stringify({
            query: `
                    query refreshToken {
                        refreshToken
                      }
                    `,
          }),
        })
      },
      handleFetch: (accessToken, data) => {
        setAccessToken(accessToken)
      },
      handleError: (err, op) => {
        sessionStorage.clear()
        ClearAccessToken()
        console.warn('Your refresh token is invalid. Try to relogin')
        console.error(err)
      },
    }),
    onError(({ graphQLErrors, networkError, response }) => {
      // all graphQl error will be thrown here
      //network error will navigate to error page
      if (networkError) {
        apiNum = 0
        setLoading(false)
        navigateTo(AUTH_PATH.NOT_FOUND)
      } else {
        // if jwt expired error thrown then just prompt user to try again
        if (
          graphQLErrors &&
          graphQLErrors[0]?.message
          .toLowerCase()
          .includes('expired'.toLowerCase())
          ) {
            setSnackBar('Please try again')
          } else {
            // all other situtations should just throw any msg returned from API
            setSnackBar(graphQLErrors[0].message)
          }
          
          if (
            graphQLErrors[0]?.message
            .toLowerCase()
            .includes('not authenticated'.toLowerCase()) ||
            graphQLErrors[0]?.message
            .toLowerCase()
            .includes(
              'Token is not valid, please try login again'.toLowerCase()
              ) ||
              graphQLErrors[0]?.message
              .toLowerCase()
              .includes('Token is blacklisted, please login again'.toLowerCase())
              ) {
                console.warn('Your token is invalid. Try to relogin')
                sessionStorage.clear()
                //when token is empty it will auto navigate to login page
                ClearAccessToken()
                navigateTo(AUTH_PATH.LOGIN)
                // this._socketService.socketDisconnection();
        }
      }
    }),
    requestLink,
    responseData,
    uploadLink as any as ApolloLink | RequestHandler,
    new HttpLink({
      uri: nodeUrl,
      credentials: 'include',
    }), //new HttpLink ends here
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
    mutate: {
      fetchPolicy: 'no-cache',
    },
  },
})
